<template>
  <div id="white-book-form" class="sb-white-book-form" :style="bg_color ? `background-color: ${bg_color}` : ''">
    <div class="inner-container fad">
      <div class="sb-white-book-form__flex">
        <div v-if="image?.filename" class="sb-white-book-form__img">
          <ImageLazy :src="image.filename" :alt="image.alt" :title="image.title" />
        </div>
        <div class="sb-white-book-form__form-card">
          <p class="h3 sb-white-book-form__form-card__title">
            {{ title }}
          </p>
          <form ref="contactForm" class="sb-white-book-form__form-card__form" @submit.prevent="submit">
            <div class="u-d-f u-f-gap-md">
              <InputField
                v-model="prospect.last_name"
                class="full-width"
                input-type="text"
                :field-title="$t('fields.lname')"
                :required="true"
                :placeholder-value="$t('fields.lname')"
                :input-rules="['required']"
              >
                <template #icon>
                  <div class="icon-user u-bg-dark-blue" />
                </template>
              </InputField>
              <InputField
                v-model="prospect.first_name"
                class="full-width"
                input-type="text"
                :field-title="$t('fields.fname')"
                :required="true"
                :placeholder-value="$t('fields.fname')"
                :input-rules="['required']"
              >
                <template #icon>
                  <div class="icon-user u-bg-dark-blue" />
                </template>
              </InputField>
            </div>
            <InputField
              v-model="prospect.email"
              input-type="email"
              :field-title="$t('fields.email')"
              :required="true"
              :placeholder-value="$t('fields.email')"
              :input-rules="['required', 'email']"
            >
              <template #icon>
                <div class="icon-email u-bg-dark-blue" />
              </template>
            </InputField>
            <InputField
              v-model="prospect.name"
              input-type="text"
              :placeholder-value="$t('fields.companyName')"
              :field-title="$t('fields.companyName')"
              :required="false"
            >
              <template #icon>
                <div class="icon-building u-bg-dark-blue" />
              </template>
            </InputField>
            <div v-if="isLoading" class="loader" />
            <p v-if="error" class="error" :class="{ 'u-c-primary': success }">
              {{ error }}
            </p>
            <MainButton
              v-else
              :disabled="isFormInvalid"
              :label="button_text"
              tag="button"
              class-variant="btn-primary full-width"
              class="sb-white-book-form__form-card__btn"
            />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'

import API from '@/mixins/sp-api-mixin'
import MainButton from '@/components/Buttons/MainButton/MainButton'
import InputField from '@/components/InputField/InputField'

export default {
  name: 'SbWhiteBookForm',
  components: {
    MainButton,
    InputField,
  },
  mixins: [API],
  props: {
    image: { type: Object, required: true },
    title: { type: String, default: '' },
    // eslint-disable-next-line vue/prop-name-casing
    bg_color: { type: String, default: null },
    // eslint-disable-next-line vue/prop-name-casing
    button_text: { type: String, default: null },
  },
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      isFormInvalid: true,
      success: false,
      prospect: {
        first_name: null,
        last_name: null,
        email: null,
        name: null,
      },
    }
  },
  watch: {
    prospect: {
      handler() {
        this.checkFormValidity()
      },
      deep: true,
    },
  },
  methods: {
    checkFormValidity() {
      for (const item of Object.entries(this.$refs)) {
        this.isFormInvalid = item[1].v$ && item[1].v$.$invalid
        if (this.isFormInvalid) break
      }
    },
    clearProspect() {
      this.prospect = {
        first_name: null,
        last_name: null,
        email: null,
        name: null,
      }
    },
    submit() {
      if (!this.isFormInvalid) {
        this.isLoading = true
        this.prospect.is_white_book = true
        const { $api } = useNuxtApp()
        this.spRequest({
          req: $api().prospect.subscribeProspect(this.prospect),
        })
          .then(() => {
            this.$refs.contactForm.reset()
            this.success = true
            this.error = this.$t('subscribe.thanksTitle')
            this.clearProspect()
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },
  },
}
</script>

<style lang="scss">
.sb-white-book-form {
  padding: $spacing-xxl 0;

  &__flex {
    display: flex;
    gap: $spacing-xl;
    align-items: center;
    justify-content: space-between;

    @include mq($mq-sm) {
      flex-direction: column-reverse;
    }
  }

  &__form-card {
    flex: 0 0 calc(50% - 40px / 2);
    background: var(--white);
    box-shadow: 0px 5px 30px rgba(5, 23, 47, 0.15); // TODO: define a variable
    border-radius: $spacing-md;
    padding: $spacing-md;

    @include mq($mq-sm) {
      width: 100%;
    }

    &__form-origin {
      margin-top: 43px;
    }

    &__title {
      margin-bottom: $spacing-md;
    }

    &__img {
      margin-bottom: $spacing-md;

      img {
        display: block;
        max-width: 150px;
      }
    }

    &__subtitle {
      color: var(--steel-grey);
    }

    &__btn {
      margin-top: $spacing-md;
    }
  }

  &__img {
    flex: 0 0 calc(50% - 40px / 2);

    @include mq($mq-md) {
      flex: 0 0 calc(50% - 40px / 2);
    }

    @include mq($mq-sm) {
      margin-bottom: $spacing-md;
    }

    img {
      display: block;
      max-width: 100%;
    }
  }
}
</style>
